import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import H2 from "../components/H2"
import Container from "../components/Container"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import school_group from "../images/school_group.svg"
import kid_dreaming from "../images/kid_dreaming.svg"
import Img from "gatsby-image"
import ImageBlock from "../components/ImageBlock"
import { Grid } from "@material-ui/core"
import theme from "../themes"
import ImageFluid from "../components/ImageFluid"

const PaedagogikPage = ({ data }) => (
  <Layout>
    {console.log(data)}

    <SEO title="Pädagogik" />

    <Hero marginBottom={"240px"}>
      <Container>
        <H1>Pädagogik</H1>
        <p>Hier werden Träume aufgefangen!</p>
      </Container>

      <ImageBlock
        src={kid_dreaming}
        width={"150%"}
        widthSm={"100%"}
        widthMd={"80%"}
        position={{
          position: "absolute",
          bottom: "0",
          right: "5%",
          zIndex: "-1",
        }}
        positionSm={{
          bottom: "0%",
          right: "-10%",
        }}
      />
    </Hero>

    <Section marginBottom="120px">
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <H2 marginBottom="24px">Leitbild der Kita Traumfänger</H2>

            <p>
              Die Kita Traumfänger legt grossen Wert auf die Verwirklichung der
              individuellen Bedürfnisse und Träume der Kinder. Jedes Kind ist
              voller Träume und Fantasie. Uns ist es wichtig, die Kinder in
              ihrer Ganzheit zu akzeptieren. Dadurch bekommen die Kinder bei uns
              die Möglichkeit zu lernen und im Alltag bedürfnisorientierte
              Entscheidungen zu treffen.
            </p>
            <p>
              Schon vor der Geburt setzen sich Kinder aktiv mit der Umwelt
              auseinander. Wir bieten den Kindern Entwicklungsräume, die dazu
              dienen, dass sie eigenständig und spielerisch die Umwelt entdecken
              und erforschen können.
            </p>

            <p>
              Jedes Kind wird in die Gruppe integriert und vollumfänglich
              akzeptiert. Alle Kulturen und Religionen werden respektiert. Jedes
              Kind bekommt bei uns die Chance sich frei zu entfalten. Wir
              unterstützen die Kinder in jeder Situation. Das Wohl der Kinder
              steht bei uns im Zentrum.
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={school_group}
              style={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Section>

    <Section>
      <Container>
        <H2 marginBottom="40px">
          Eingewöhnung. Jeder braucht mal seine Zeit für etwas neues.
        </H2>

        {console.log(data)}

        <ImageFluid
          rounded
          width={"100%"}
          widthMd={"75%"}
          fluid={data.image3.childImageSharp.fluid}
          borderColor={theme.main.colors.green}
        />

        <p>
          Die Eingewöhnungszeit ist für uns alle - dem Kind, den Eltern und den
          BetreuerInnen ausserordentlich wichtig. Damit sich das Kind mit dem
          neuen Lebensumfeld vertraut macht und geborgen fühlt, ist ein
          schrittweises Einleben notwendig.
        </p>
        <p>
          Wir achten darauf, dass die neu eintretenden Kinder von einer
          Mitarbeiterin (feste Bezugsperson) begleitet wird. Die Eingewöhnung
          wird individuell gestaltet und mit den Eltern im Vorfeld besprochen.
          Die Eingewöhnung endet erst, wenn sich das Kind von der Bezugsperson
          trösten lässt. So wird die Bindung zwischen dem Kind und der
          Bezugsperson gestärkt.
        </p>
        <p>
          Wir bieten den Eltern und den Kindern Schnupperstunden an. Indem sie
          unseren Kita-Alltag mitverfolgen und sich einen persönlichen Eindruck
          verschaffen können.
        </p>
      </Container>
    </Section>
  </Layout>
)

export default PaedagogikPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "kids_playing.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 400, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    image2: file(relativePath: { eq: "kid_bubbles.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 400, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    image3: file(relativePath: { eq: "kids_parents_group.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    image4: file(relativePath: { eq: "school_group" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 320, height: 320) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
